.ib {
  display: inline-block;
  padding: 10px;
}

.fl {
  float: left;
}

.newIngredientAddIngredientTitle {
  font-size: 2em;
  text-align: center;
  font-weight: bold;
}

.newIngredientAddDetailsFormContainer {
  max-width: 500px;
}

.ingredientPreviewName {
  font-size: 1.3em;
  text-align: center;
  padding-bottom: 5px;
}

.inventoryNotes {
  max-width: 200px;
  font-size: 0.8em;
  line-height: 1em;
}

.ingredientPreviewImage {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.ingredientPreviewOverviewDetails {
  margin: 0px auto;
  max-width: 250px;
  font-size: 1.1em;
  line-height: 1.2em;
  word-break: normal;
}

.ingredientPreviewLineItem {
  padding-top: 7px;
}

.newIngredientMultiselectContainer {
  max-width: 400px;
}

/**  Nutirion Facts */
.nutritionFactsOutline {
  border: 2px solid black;
  padding: 5px;
}

.nutritionFactsLabel {
  font-weight: 900;
  font-size: 1.8em;
  border-bottom: 1px solid black;
}

.nutritionFactsServingsPerContainer {
  font-size: 0.9em;
}

.nutritionFactsServingSizeLabel {
  display: inline-block;
  font-weight: 800;
}

.nutritionFactsServingSizeAmount {
  display: inline-block;
  float: right;
  font-weight: 800;
}

.nutritionFactsLargeBarDivider {
  height: 0.6em;
  width: 100%;
  margin: 2px auto;
  background-color: black;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.6);
}

.nutritionFactsMediumBarDivider {
  height: 0.2em;
  width: 100%;
  margin: 2px auto;
  background-color: black;
}

.nutritionFactsMediumBarDivider.t2 {
  margin-top: 4px;
}

.nutritionFactsAmountPerServing {
  font-weight: 800;
  height: 0.3em;
  font-size: 0.6em;
  float: left;
}

.nutritionFactsCloriesContainer {
  font-size: 1.3em;
  border-bottom: 0.2em solid black;
  padding-bottom: 1px;
}

.nutritionFactsCaloriesNumber {
  line-height: 1.1em;
  font-weight: 800;
  font-size: 2em;
  float: right;
}

.nutritionFactsPrimaryDetails {
  clear: both;
}

.nutritionFactsSingleRow {
  border-bottom: 1px solid #ccc;
}

.nutritionFactsCalories {
  padding-top: 0.4em;
  font-weight: 800;
  font-size: 1.2em;
}

.nutritionFactsTabSpacing0 {
  width: 0px;
  float: left;
  height: 0px;
}

.nutritionFactsTabSpacing1 {
  width: 15px;
  float: left;
  height: 2px;
}

.nutritionFactsTabSpacing2 {
  width: 30px;
  float: left;
  height: 2px;
}

.nutritionFactsIb {
  display: inline-block;
}

.nutritionFactsIb.right {
  padding-left: 10px;
  float: right;
}

.nutritionFactsSingleRow:last-child {
  border-bottom: none;
}

.nutritionFactsFooterDisclaimer {
  max-width: inherit;
  font-size: 0.6em;
  font-weight: 500;
  margin: 0px;
}

.nutirionFactsFooterWrapper {
  max-width: 200px;
  margin: 0px;
}

.nutritionFactsPctDailyValues {
  margin: 2px 0px;
  float: right;
  font-weight: bold;
  font-size: 0.8em;
}

/** Ingredient Page Main */
.ingredientPageAddIngredientContainer {
  display: block;
}

.ingredientPageAddIngredientContainer.hidden {
  display: none;
}

.ingredientPageAddIngredientViewToggle {
  margin: 20px;
  padding-bottom: 20px;
}

.floatRight {
  float: right;
  width: 300px;
}

.w700 {
  width: 700px;
}

.floatLeft {
  float: left;
}

.clearBoth {
  clear: both;
}

.nutritionCalculatorIngredientsBox {
  line-height: 1.1em;
  font-size: 0.9em;
  margin: 5px 0px;
  border: 2px solid black;
  padding: 2px 4px;
}