.navigation-top-bar {
  height: 50px;
  background-color: rgb(255, 255, 255);
  border-bottom-color: rgb(97, 97, 97);
  border-bottom-style: none;
  border-bottom-width: 0px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 5px 0px;
  box-sizing: border-box;
  color: rgb(97, 97, 97);
  display: block;
  font-size: 14px;
  font-weight: 400;
  line-height: 23px;
  margin: 0px;
  overflow-x: visible;
  overflow-y: visible;
  padding: 0px;
  position: fixed;
  top: 0px;
  width: 1900px;
  z-index: 999;
}

@media only screen and (max-width: 991px) and (min-width: 769px) {
  /* CSS that should be displayed if width is equal to or less than 991px and larger
  than 768px goes here */
}

@media only screen and (max-width: 991px) {
  /* CSS that should be displayed if width is equal to or less than 991px goes here */
}

.navigation-top-bar div {
  display: inline-block;
  margin: 5px;
  padding: 5px;
}

.navigation-side-bar {
  height: calc(100% - 66px);
  position: absolute;
  padding-top: 20px;
  top: 30px;
  display: inline-block;
  border-right: 2px solid rgba(133, 133, 133, 0.1);
  width: 200px;
  padding-left: 0px;
}

.navigation-side-bar-spacing {
  display: inline-block;
  width: 245px;
}

.navigation-side-bar li {
  display: block;
  padding-left: 15px;
  line-height: 30px;
}

.navigation-side-bar li:hover {
  background-color: #dddddd;
}

#logo {
  display: none;
}

#navigation-wrap {
  display: none;
}