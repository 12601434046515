.module {
  padding: 10px 0px;
  border-bottom: 1px solid #ccc;
}

.module.small {
  max-width: 500px;
}

.floatRight {
  float: right;
  width: 300px;
}

.w700 {
  width: 700px;
}

.floatLeft {
  float: left;
}

.clearBoth {
  clear: both;
}