.defaultLookupSelectionContainer {
  max-width: 600px;
  margin: 10px;
}

.defaultLookupSelectionHeader {
  margin-bottom: 3px;
}

.commonToggleDiv {
  padding-right: 20px;
  display: inline-block;
  font-weight: 700;
  color: #555;
  margin-left: 20px;
  border-bottom: 1px solid #ddd;
  -webkit-user-select: none;
  user-select: none;
}

.moldSizePageOutterContainer {
  border: 1px solid #ccc;
  margin: 5px;
  max-width: 900px;
  padding: 5px;
}

.carat {
  border: solid black;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 4px;
  margin-bottom: 2px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.carat.down {
  border: solid black;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 4px;
  margin-bottom: 2px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}