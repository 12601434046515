.packagingMainPageContainer {
  max-width: 800px;
  border: 2px solid black;
}

.packagingMainPageContainer.hidden {
  display: none;
}

.packagingPageOutterContainer {
  border: 1px solid #ccc;
  margin: 5px;
  max-width: 900px;
  padding: 5px;
}

.defaultLookupSelectionContainer {
  max-width: 600px;
  margin: 10px;
}

.defaultLookupSelectionHeader {
  margin-bottom: 3px;
}

.packagingToggleDiv {
  padding-right: 20px;
  display: inline-block;
  font-weight: 700;
  color: #555;
  margin-left: 20px;
  border-bottom: 1px solid #ddd;
  -webkit-user-select: none;
  user-select: none;
}

.carat {
  border: solid black;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 4px;
  margin-bottom: 2px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.carat.down {
  border: solid black;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 4px;
  margin-bottom: 2px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.packagingCategoryContainer {
  max-width: 300px;
  margin: 5px;
}

.unitsPerItemHeader {
  margin-bottom: 0px;
  margin-left: 10px;
}

.unitsPerItemFormListItem {
  margin-left: 15px;
  line-height: 1.9em;
}

.unitsPerItemDetailedInput {
  margin-left: 10px;
}