.beanContainer {
  width: 900px;
  margin: 0px auto;
}

.beanMasterList {
  width: 500px;
  border: 1px solid black;
  overflow-x: hidden;
  float: left;
  display: inline-block;
}

.beanPreview {
  width: 300px;
  height: 300px;
  border: 1px solid gray;
  display: inline-block;
  float: right;
}

.beanOptions {
  padding: 10px;
  top: 500px;
  float: left;
}

.beanDetails {
  width: 500px;
  height: 200px;
  border: 1px solid black;
}

.beanDetailsName {
  text-align: center;
  width: 486px;
  padding: 7px;
  font-weight: 500;
  font-size: 24px;
}

.beanDetailsCost {
  padding: 10px;
}

.beanDetailsNotes {
  padding: 10px;
}

.flavorProfileAdditionDetails {
  width: 200px;
  float: left;
  display: inline-block;
}

.flavorProfileAdditionPreview {
  display: inline-block;
}