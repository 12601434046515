.moldSizeMainPageContainer {
  max-width: 800px;
  border: 2px solid black;
}

.moldSizeMainPageContainer.hidden {
  display: none;
}

.moldSizeAdvancedSearch {}

.moldSizeAdvancedSearch.hidden {
  display: none;
}

.barMoldIdNotes {
  color: #444;
  font-size: 0.7em;
  margin-bottom: 7px;
}