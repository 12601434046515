.batchCreationContainer {
  margin: 10px;
  width: 1100px;
}

.batchCreation {
  display: inline-block;
}

.batchCreation.leftSide {
  float: left;
  max-width: 800px;
}

.batchCreation.rightSide {
  float: left;
  max-width: 290px;
  min-width: 250px;
  margin-left: 30px;
}

.batchCreationClear {
  clear: both;
}

.batchCreationDetailsIntentionSelection {
  width: 500px;
}

/* Search / Lookup */
.batchLookupContainer {
  max-width: 300px;
}

.splitBatchContainer {
  margin: 10px;
}

.splitBatchNewLabel.valid {
  border: 1px solid #00cc00;
}

.splitBatchNewLabel.invalid {
  border: 1px solid red;
}