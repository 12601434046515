.app-container {
  margin-top: 55px;
  display: inline-block;
  width: calc(100% - 245px);
  background-color: white;
  margin-left: 203px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}