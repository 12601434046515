.barAddEditOutterContainer {
  border: 1px solid black;
  margin: 20px;
  padding: 10px;
  max-width: 1000px;
}

.barBatchSelection {
  max-width: 300px;
}

.barMoldDetailsContainerForBarCreation {
  margin-top: 10px;
  margin-bottom: 20px;
  width: 266px;
  display: inline-block;
  padding: 0px 10px 10px 10px;
  border-right: 1px solid #ccc;
}

.barBatchSelectionPctInputs {
  min-width: 900px;
}

.moldSelectionLabel {
  margin-top: 3px;
  text-align: center;
  font-size: 1.4em;
  border-bottom: 1px solid #ccc;
}

.moldSelectionBarMoldImagePreview {
  padding: 0px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-height: 200px;
  max-width: 200px;
}

.generateBarGroupLabel {
  display: inline-block;
  padding-left: 10px;
}

/*  Bar Preview */
.barPreviewMold {
  padding: 20px;
  width: 300px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  border-bottom: 1px solid #ccc;
}

.barPreviewPackagingImg {
  width: 120px;
  height: 120px;
}

.barPreviewPackagingSelectionTypeSingleItemContainer {
  width: 120px;
  margin: 0px auto;
  padding: 2px;
  display: inline-block;
}

.barPreviewContainer {
  width: 400px;
  border: 1px solid black;
}

.barSelectionTypeSingleItemDisplayLabel {
  padding: 7px;
  text-align: center;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.barPreviewPackagingTitle {
  margin-top: 10px;
  float: left;
  padding-left: 20px;
}

.barPreviewClearBoth {
  clear: both;
}

.barPreviewPackaging {
  text-align: center;
  border-bottom: 1px solid #ccc;
  font-size: 1.1em;
}

.barPreviewIngredients {}

.barPreviewIngredientsContainer {
  font-size: 1.1em;
  margin: 20px;
  line-height: 2em;
}

.barPreviewIngredientsContainerSingleIngredient {
  border-bottom: 1px solid #ddd;
}

.barPreviewIngredientsContainerSingleIngredient.highlighted {
  background-color: #dfc;
  text-align: center;
  font-size: 1.2em;
  font-weight: 600;
}

.barPreviewNutritionFacts {
  width: 240px;
  margin: 20px;
  margin-left: auto;
  margin-right: auto;
  display: block;
}