.tastingMainPageContainer {
  max-width: 800px;
}

.tastingMainPageContainer.hidden {
  display: none;
}

.tastingAdvancedSearch {}

.tastingAdvancedSearch.hidden {
  display: none;
}

.tastingPageOutterContainer {
  border: 1px solid #ccc;
  margin: 5px;
  max-width: 900px;
  padding: 5px;
}

.tastingAddEditPreview {
  border: 1px solid #ccc;
  margin: 5px;
}

.addEditTastingTypeContainer {
  width: 500px;
  margin: 5px;
}

.addEditTastingBarSelectionContainer {
  width: 500px;
  margin: 5px;
}

.tastingSideBySideBarImages {
  border: 1px solid #555;
  margin: 5px;
}

.barSideBySideBarImage {
  width: 270px;
  margin: auto;
  display: block;
}

.barSideBySidePackagingTd {
  text-align: center;
  border-bottom: 1px solid #ccc;
  font-size: 1.1em;
}

.tastingSideBySideBarNutritionFactsTd {
  max-width: 250px;
  margin: 0px auto;
}

.tastingPreviewNotes {
  width: 350px;
  min-height: 50px;
}

.barAlphabeticalToTastingOrderMap {
  width: 300px;
}

.tastingPreviewTitle {
  margin: auto;
  padding: 20px 0px 0px 0px;
  width: 620px;
  font-weight: 600;
  font-size: 1.4em;
  text-align: center;
}

.tastingPreviewTitle.minor {
  padding: 5px 0px 20px 0px;
  font-size: 1.1em;
  font-weight: 300;
}

.barTastingPreviewTitle {
  line-height: 20px;
  text-align: center;
}

.barTastingPreviewLabel {
  line-height: 20px;
  padding-bottom: 20px;
  text-align: center;
  border-bottom: 1px solid #ccc;
  max-width: 700px;
  margin: auto;
}

.tastingPreviewContainer {
  width: 800px;
  margin: 20px;
}

.tastingDifficultyContainer {
  text-align: center;
  display: inline-block;
}

.tastingDifficulty {
  padding: 10px 20px;
  font-size: 30px;
  font-weight: bold;
  border: 1px solid #888888;
  max-width: 200px;
  border-radius: 30px;
}

/** Got some good color pics off of cssgradient.io */
.tastingDifficulty.Easy {
  color: black;
  background-image: linear-gradient(#98FB98, #4FFFB0);
}

.tastingDifficulty.Medium {
  color: black;
  background-image: linear-gradient(#007FFF, #00CED1);
}

.tastingDifficulty.Difficult {
  color: black;
  background-image: linear-gradient(#fd5c63, #FF7F50);
}

.tastingDifficulty.Challenging {
  color: black;
  background-image: linear-gradient(#C80815, #F2003C);
}

.tastingPreviewShowAnswersButton {
  min-width: 250px;
  min-height: 40px;
  font-family: 'Nunito', sans-serif;
  font-size: 20px;
  text-transform: uppercase;
  letter-spacing: 1.3px;
  font-weight: 700;
  color: #313133;
  background: #4FD1C5;
  background: linear-gradient(90deg, rgba(200, 200, 200, .64) 0%, rgba(200, 200, 200, .64) 100%);
  border: none;
  border-radius: 1000px;
  box-shadow: 12px 12px 24px rgba(200, 200, 200, .64);
  transition: all 0.3s ease-in-out 0s;
  cursor: pointer;
  outline: none;
  position: relative;
  padding: 10px;
}

.tastingPreviewShowAnswersButton::before {
  content: '';
  border-radius: 1000px;
  min-width: calc(250px + 12px);
  min-height: calc(40px + 12px);
  border: 2px solid rgba(200, 200, 200, .64);
  box-shadow: 0 0 60px rgba(200, 200, 200, .64);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: all .3s ease-in-out 0s;
}

.tastingPreviewShowAnswersButton:hover, .tastingPreviewShowAnswersButton:focus {
  color: #313133;
  transform: translateY(-1px);
}

.tastingPreviewShowAnswersButton:hover::before, .tastingPreviewShowAnswersButton:focus::before {
  opacity: 1;
}

.tastingPreviewShowAnswersButton::after {
  content: '';
  width: 20px;
  height: 20px;
  border-radius: 100%;
  border: 2px solid rgba(155, 155, 155, .64);
  position: absolute;
  z-index: -1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: ring 2.5s 15;
}

.tastingPreviewShowAnswersButton:hover::after, .tastingPreviewShowAnswersButton:focus::after {
  animation: none;
  display: none;
}

@keyframes ring {
  0% {
    width: 250px;
    height: 80px;
    opacity: 1;
  }

  100% {
    width: 350px;
    height: 100px;
    opacity: 0;
  }
}

.tastingButtonContainer {
  text-align: center;
  padding-bottom: 30px;
}

.tastingButtonContainer.hidden {
  display: none;
}

@keyframes highlight {
  0% {
    transform: rotate(15deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

.tastingSideBySideIngredients {}

.tastingSideBySideIngredients.Cacao,
.tastingSideBySideIngredients.Dairy,
.tastingSideBySideIngredients.Sweetener,
.tastingSideBySideIngredients.Cocoa {
  display: table-row;
}

.tastingSideBySideIngredients.Cacao.hidden,
.tastingSideBySideIngredients.Dairy.hidden,
.tastingSideBySideIngredients.Sweetener.hidden,
.tastingSideBySideIngredients.Cocoa.hidden {
  visibility: collapse;
}

.qrhidden {
  display: none;
}

.toggleQRCodeButton {
  margin: auto;
}

@media only screen and (max-width: 740px) {

  .navigation-top-bar,
  .navigation-side-bar {
    display: none;
  }

  .app-container {
    z-index: 5;
    margin-top: 0px;
    width: 600px;
  }

  .tastingPreviewContainer {
    max-width: 600px;
  }

  .barSideBySideBarImage {
    width: 210px;
  }

  .tastingPreviewTitle {
    padding: 10px 0px 0px 0px;
    width: 520px;
  }

  .tastingPreviewContainer {
    margin: 0px;
  }
}